// useFetchWithAuth.tsx
import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrgRoleData } from 'core/state/useOrgRoleData';
import { useNavigate } from 'react-router-dom';
import { ErrorContext } from 'core/context/ErrorContext';

interface FetchOptions extends RequestInit {
  headers?: HeadersInit;
}

export const useFetchWithAuth = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [orgRoleData] = useOrgRoleData();
  const navigate = useNavigate();
  const { setError } = useContext(ErrorContext);

  const getData = async (
    url: string,
    options: FetchOptions = {},
    overrideGenericErrorHandling?: boolean,
    orgId?: string,
  ): Promise<Response> => {
    try {
      // Fetch access token
      const token = await getAccessTokenSilently();

      // Perform the fetch request
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
          orgId: orgId || orgRoleData?.org?.id,
          'Content-Type': 'application/json',
        },
      });
      // Handle errors
      if (!response.ok && !overrideGenericErrorHandling) {
        let errorMessage;
        if (response.status === 400) {
          errorMessage = response.statusText;
          try {
            const errorData = await response.json();
            errorMessage = errorData.message || errorMessage;
          } catch (e) {
            // Ignore JSON parsing errors
          }
        } else {
          errorMessage =
            'We have encountered an issue. Please try again later.';
        }

        setError({
          status: response.status,
          message: errorMessage,
        });

        throw new Error(errorMessage);
      }

      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      setError({ message: (error as Error).message });
      throw error;
    }
  };

  return { getData };
};
