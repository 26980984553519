export const appConstants = {
  ROLES: {
    SUPER: 'SUPER',
    VIEWER: 'VIEWER',
    OWNER: 'OWNER',
  },
  ROUTES: {
    SITES_PAGES: '/sites/site-manager',
  },
  BREADCRUMBS: {
    SEARCH_SETUP: {
      MAIN: 'Search Setup',
      ITEMS: {
        SITES_PAGES: 'Sites and Pages',
        CATEGORY_MANAGER: 'Category Manager',
        ADD_SITE: 'Add Site',
        EDIT_SITE: 'Edit Site',
        CONFIGURE_SEARCH: 'Configure Search',
        CONFIGURE_NEW_SEARCH: 'Configure a new search',
        TRY_MY_SEARCH: 'Try My Search',
      },
    },
    DASHBOARD: {
      MAIN: 'Dashboard',
      ITEMS: {
        SEARCH_METRICS: 'Search Metrics',
        PLAN_METRICS: 'Plan Metrics',
      },
    },
  },
};
